:root{
  --blue-skan:#344b7b;
  --jaune-skan:#f9ba14;
}
body{
  margin: 0;
  padding: 0;
}


.bg-navbar, .page-item.active .page-link{
  background-color: #3B546E !important;
}

.nav-link{
  color: whitesmoke !important;
}

.btnDeconnexion, .upload-btn{
  background-color: #293b4e !important;
  color: white !important;
}
.active {
  border-bottom: 4px solid #f9ba14;
  font-weight: bold !important;
}

.nb{
  font-weight: bold;
  color: #344b7b;
  size: 1.2rem;
}

#close-modal-btn{
  background-color: gray !important;
}

.cursor {
  cursor: pointer;
}

.displayBlock{
  display:block
}
a, a:hover{
  color: black;
  text-decoration: none;
}
.bg-GlobalTickets{
  background-color: #293b4e71;
}
.input-group-text{
  border-start-start-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
